<template>
  <ag-modal-container
    title="Références supprimées"
    width="900px"
    @close="back"
  >
    <div
      v-if="!loadingData && !partNumberList.length"
      class="text-center title mt-5"
    >
      Aucun élément
    </div>

    <part-number-list
      v-else
      :value="partNumberList"
      :selected.sync="selectedPartNumberList"
      :loading="loadingData"
      light
    />

    <template v-slot:footer>
      <v-spacer />
      <ag-confirm-dialog
        title="Confirmation"
        :message="restoreMessage"
        cancel-label="Annuler"
        confirm-label="Restaurer"
        validation-btn-color="accent"
        @confirm="restoreSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRestore"
            :disabled="!selectedPartNumberList.length || loadingRestore"
            color="accent"
            class="ml-5"
            v-bind="attrs"
            v-on="on"
            v-text="'Restaurer'"
          />
        </template>
      </ag-confirm-dialog>
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import PartNumberList from '../components/PartNumberList'

export default {
  name: 'PartNumberRestoreContainer',
  components: {
    AgModalContainer,
    AgConfirmDialog,
    PartNumberList,
  },
  data () {
    return {
      partNumberList: [],
      selectedPartNumberList: [],
      loadingData: false,
      loadingRestore: false,
    }
  },
  computed: {
    restoreMessage () {
      const count = this.selectedPartNumberList.length
      return `Souhaitez-vous restaurer ${count} référence(s)`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'PartNumberRestore') {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async fetchData () {
      this.loadingData = true
      this.partNumberList = await Repositories.partNumber.getList({ archived: true })
      this.loadingData = false
    },
    async restoreSelected () {
      try {
        this.loadingRestore = true
        const count = this.selectedPartNumberList.length
        await Repositories.partNumber.restore(
          this.selectedPartNumberList.map(select => select._id),
        )

        this.selectedPartNumberList = []
        this.fetchData()

        this.showSuccessNotification(`Vous avez restoré ${count} référence(s)`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRestore = false
      }
    },
  },
}
</script>
